:root {
    --bs-body-color: #{$dark};
    --bs-body-bg: #{$white};
    --bs-secondary-bg: #{$white};
    --bs-white: #{$gray-100};
    --bs-footer-bg: #292838;
    --bs-footer-alt-bg: #23222f;
    --bs-form-bg: #{$gray-300};
    --bs-bg-white: #{$white};
    --bs-text-color: #{$gray-700};
}
