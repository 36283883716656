[data-bs-theme="dark"] {
    $gray-100: #141c26;
    $gray-200: #18222e;
    $gray-300: #1a2532;
    $gray-400: #ecf0ff;
    $gray-500: #adb5bd;
    $gray-600: #bfc8e2;
    $gray-700: #ced4da;
    $gray-800: #eff2f7;
    $gray-900: #f3f6f9;

    $grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900,
    );

    // Prefix for :root CSS variables
    @each $color, $value in $grays {
        --bs-gray-#{$color}: #{$value};
    }

    --bs-body-color: #{$gray-900};
    --bs-body-bg: #{$gray-100};
    --bs-secondary-bg: #{$gray-300};
    --bs-border-color: #{$gray-300};
    --bs-dark: #{$gray-900};
    --bs-dark-rgb: #{to-rgb($gray-900)};
    --bs-light: #{$gray-200};
    --bs-white: #{$gray-300};
    --bs-form-bg: #{$gray-300};
    --bs-light-rgb: #{to-rgb($gray-200)};
    --bs-white-rgb: #{to-rgb($gray-300)};
    --bs-footer-bg: #{$gray-300};
    --bs-footer-alt-bg: #{$gray-100};
    --bs-bg-white: #{$gray-200};
    --bs-text-color: #{$gray-500};
}
