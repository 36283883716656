/* 
// 1. General
*/

a {
    text-decoration: none !important;
    outline: none;
}
body {
    font-family: $font-primary;
    color: var(--bs-dark);
    font-weight: 500;
}
p {
    line-height: 1.8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-secondary;
    font-weight: 700;
}

.row > * {
    position: relative;
}
